<template>
    <div>
        <ts-page-title
            :title="$t('holidayRule.pageTitle')"
            :breadcrumb="[
                { text: $t('home'), href: '/' },
                {
                    text: $t('holidayRule.pageTitle'),
                    active: true
                }
            ]"
        />
        <div>
            <ts-panel>
                <ts-panel-wrapper>
                    <div class="tw-flex tw-justify-between">
                        <div class="tw-flex">
                            <div class="tw-px-2 tw-w-56">
                                <Select
                                    v-model="model.subs_day1"
                                    style="width: 200px;"
                                    clearable
                                >
                                    <Option
                                        v-for="(substituteDay,
                                        index) in substituteDays"
                                        :key="index"
                                        :value="substituteDay.value"
                                        >{{ substituteDay.text }}
                                    </Option>
                                </Select>
                                <i class="tw-gray-400 tw-items-center"
                                    ><small>{{
                                        $t("holidayRule.subsDay1")
                                    }}</small></i
                                >
                            </div>
                            <div class="tw-px-2 tw-w-56">
                                <Select
                                    v-model="model.subs_day2"
                                    clearable
                                    style="width: 200px;"
                                >
                                    <Option
                                        v-for="(substituteDay,
                                        index) in substituteDays"
                                        :key="index"
                                        :value="substituteDay.value"
                                        >{{ substituteDay.text }}
                                    </Option>
                                </Select>
                                <i class="tw-gray-400 tw-items-center"
                                    ><small>{{
                                        $t("holidayRule.subsDay2")
                                    }}</small></i
                                >
                            </div>
                        </div>
                        <div class="tw-justify-end tw-space-x-2">
                            <Input
                                search
                                v-model="search"
                                :placeholder="$t('holidayRule.search')"
                                :style="width"
                                @on-focus="() => (width = 'width: 500px')"
                                @on-blur="() => (width = 'width: 300px')"
                            />
                        </div>
                    </div>
                </ts-panel-wrapper>

                <div id="container-table">
                    <ts-table :records="model.holiday_detail">
                        <template v-slot:thead>
                            <thead>
                                <tr>
                                    <th
                                        v-for="(column, key) in columns"
                                        :key="key"
                                    >
                                        {{ column.name }}
                                    </th>
                                    <th style="text-align:center; width: 2px;">
                                        <a
                                            href="#"
                                            class="btn btn-info btn-xs"
                                            @click.prevent="addNewRow"
                                        >
                                            <i class="fas fa-plus"></i>
                                        </a>
                                    </th>
                                </tr>
                                <TableProgressBar
                                    v-show="loading"
                                    :columnLength="columns.length + 1"
                                />
                            </thead>
                        </template>
                        <template v-slot="{ record, index }">
                            <td>
                                <input
                                    type="text"
                                    :value="record.holiday_name_kh"
                                    @input="
                                        e =>
                                            (record.holiday_name_kh =
                                                e.target.value)
                                    "
                                    class="form-control form-control-sm only-border-bottom"
                                />
                                <div
                                    class="invalid-feedback"
                                    v-if="
                                        errors.has(
                                            'holiday_detail.' +
                                                index +
                                                '.holiday_name_kh'
                                        )
                                    "
                                >
                                    {{
                                        errors.first(
                                            "holiday_detail." +
                                                index +
                                                ".holiday_name_kh"
                                        )
                                    }}
                                </div>
                            </td>
                            <td>
                                <input
                                    type="text"
                                    :value="record.holiday_name_en"
                                    @input="
                                        e =>
                                            (record.holiday_name_en =
                                                e.target.value)
                                    "
                                    class="form-control form-control-sm only-border-bottom"
                                    :class="{
                                        'is-invalid': errors.has(
                                            'holiday_detail.' +
                                                index +
                                                '.holiday_month'
                                        )
                                    }"
                                />
                                <div
                                    class="invalid-feedback"
                                    v-if="
                                        errors.has(
                                            'holiday_detail.' +
                                                index +
                                                '.holiday_name_en'
                                        )
                                    "
                                >
                                    {{
                                        errors.first(
                                            "holiday_detail." +
                                                index +
                                                ".holiday_name_en"
                                        )
                                    }}
                                </div>
                            </td>
                            <td style="width: 200px">
                                <select
                                    v-model.number.trim="record.holiday_month"
                                    class="form-select"
                                    :class="{
                                        'is-invalid': errors.has(
                                            'holiday_detail.' +
                                                index +
                                                '.holiday_month'
                                        )
                                    }"
                                >
                                    <option
                                        v-for="(month, index) in months"
                                        :key="index"
                                        :value="month.value"
                                    >
                                        {{ month.text }}</option
                                    >
                                </select>
                                <div
                                    class="invalid-feedback"
                                    v-if="
                                        errors.has(
                                            'holiday_detail.' +
                                                index +
                                                '.holiday_month'
                                        )
                                    "
                                >
                                    {{
                                        errors.first(
                                            "holiday_detail." +
                                                index +
                                                ".holiday_month"
                                        )
                                    }}
                                </div>
                            </td>
                            <td style="width: 150px">
                                <select
                                    v-model.number.trim="record.holiday_day"
                                    class="form-select"
                                    :class="{
                                        'is-invalid': errors.has(
                                            'holiday_detail.' +
                                                index +
                                                '.holiday_day'
                                        )
                                    }"
                                >
                                    <option
                                        v-for="(day, index) in days(
                                            record.holiday_month
                                        )"
                                        :key="index"
                                        :value="day"
                                    >
                                        {{ day }}</option
                                    >
                                </select>
                                <div
                                    class="invalid-feedback"
                                    v-if="
                                        errors.has(
                                            'holiday_detail.' +
                                                index +
                                                '.holiday_day'
                                        )
                                    "
                                >
                                    {{
                                        errors.first(
                                            "holiday_detail." +
                                                index +
                                                ".holiday_day"
                                        )
                                    }}
                                </div>
                            </td>
                            <td class="tw-align-middle">
                                <a
                                    href="#"
                                    v-tooltip="$t('delete')"
                                    class="text-danger"
                                    @click.prevent="destroy(record)"
                                >
                                    <i class="fas fa-trash"></i>
                                </a>
                            </td>
                        </template>
                    </ts-table>
                </div>
                <ts-panel-wrapper>
                    <div class="row ts-mt-6">
                        <div
                            class="col-sm-12 col-lg-12 col-md-12 tw-text-right"
                        >
                            <ts-button
                                color="primary"
                                class="ml-2"
                                :waiting="saving"
                                @click.prevent="save"
                            >
                                {{ $t("save") }}</ts-button
                            >
                        </div>
                    </div>
                </ts-panel-wrapper>
            </ts-panel>
        </div>
    </div>
</template>

<script>
import moment from "moment";
import { Errors } from "form-backend-validation";
import { cloneDeep, range, debounce } from "lodash";
import { mapState } from "vuex";
import TableProgressBar from "@/components/common/TsTable/TableProgressBar";

export default {
    name: "holiday-rule",
    components: { TableProgressBar },
    i18n: {
        messages: {
            en: {
                dayOfWeek: {
                    Monday: "Monday",
                    Tuesday: "Tuesday",
                    Wednesday: "Wednesday",
                    Thursday: "Thursday",
                    Friday: "Friday",
                    Saturday: "Saturday",
                    Sunday: "Sunday"
                }
            },
            kh: {
                dayOfWeek: {
                    Monday: "ច័ន្ទ",
                    Tuesday: "អង្គារ",
                    Wednesday: "ពុធ",
                    Thursday: "ព្រហស្បតិ៌",
                    Friday: "សុក្រ",
                    Saturday: "សៅរ៌",
                    Sunday: "អាទិត្យ"
                }
            }
        }
    },
    data() {
        return {
            model: {
                subs_day1: null,
                subs_day2: null,
                holiday_detail: []
            },
            errors: new Errors(),
            saving: false,
            loading: false,
            width: "width: 300px"
        };
    },
    computed: {
        ...mapState("humanResource/holidayRule", {
            holidayRules: "resources",
            pagination: "pagination",
            substitute: "substitute"
        }),
        search: {
            get() {
                return this.$store.state.humanResource.holidayRule.search;
            },
            set(newValue) {
                this.$store.commit(
                    "humanResource/holidayRule/SET_SEARCH",
                    newValue
                );
                this.$store.commit(
                    "humanResource/holidayRule/RESET_CURRENT_PAGE"
                );
            }
        },
        columns() {
            return [
                {
                    name: this.$t("holidayRule.holidayNameKh"),
                    sortKey: "holiday_detail.holiday_name_kh"
                },
                {
                    name: this.$t("holidayRule.holidayNameEn"),
                    sortKey: "holiday_detail.holiday_name_en"
                },
                {
                    name: this.$t("holidayRule.holidayMonth"),
                    sortKey: "holiday_detail.holiday_month"
                },
                {
                    name: this.$t("holidayRule.holidayDay"),
                    sortKey: "holiday_detail.holiday_day"
                }
            ];
        },
        substituteDays() {
            return [
                { text: this.$t("dayOfWeek.Monday"), value: "Monday" },
                { text: this.$t("dayOfWeek.Tuesday"), value: "Tuesday" },
                { text: this.$t("dayOfWeek.Wednesday"), value: "Wednesday" },
                { text: this.$t("dayOfWeek.Thursday"), value: "Thursday" },
                { text: this.$t("dayOfWeek.Friday"), value: "Friday" },
                { text: this.$t("dayOfWeek.Saturday"), value: "Saturday" },
                { text: this.$t("dayOfWeek.Sunday"), value: "Sunday" }
            ];
        },
        months: () => {
            return moment.months().map((month, index) => {
                return {
                    text: month,
                    value: index + 1
                };
            });
        },
        days: () => month => {
            if (month === "") return [];

            const startOfMonth = moment()
                .month(month - 1)
                .startOf("month");

            const endOfMonth = moment()
                .month(month - 1)
                .endOf("month");
            return range(1, endOfMonth.diff(startOfMonth, "days") + 2);
        }
    },
    methods: {
        fetchData(attributes) {
            this.loading = true;
            Promise.all([
                this.$store.dispatch(
                    "humanResource/holidayRule/fetchSubstitute"
                ),
                this.$store.dispatch(
                    "humanResource/holidayRule/fetch",
                    attributes
                )
            ])
                .then(() => {
                    this.loading = false;
                })
                .catch(error => {
                    this.loading = false;
                    this.notice({ type: "error", text: error.message });
                });
        },
        save() {
            this.errors = new Errors();
            this.saving = true;

            return this.$store
                .dispatch("humanResource/holidayRule/store", this.model)
                .then(response => {
                    return this.$store
                        .dispatch("humanResource/holidayRule/fetch")
                        .then(() => {
                            this.notice({
                                type:
                                    response.message_id == 0
                                        ? "success"
                                        : "warning",
                                text: response.message
                            });
                        });
                })
                .catch(error => {
                    if (error.errors) this.errors = new Errors(error.errors);
                    else this.notice({ type: "error", text: error.message });
                })
                .finally(() => (this.saving = false));
        },
        destroy(record) {
            this.model.holiday_detail.splice(
                this.model.holiday_detail.indexOf(record),
                1
            );
        },
        addNewRow() {
            this.model.holiday_detail.push({
                holiday_name_kh: "",
                holiday_name_en: "",
                holiday_day: "",
                holiday_month: ""
            });
        },
        notice(not) {
            this.$Notice[not.type]({
                title: "HOLIDAY RULE",
                desc: not.text
            });
        }
    },
    watch: {
        search: debounce(function(search) {
            this.fetchData({ search });
        }, 500),
        substitute: function(substitute) {
            this.model.subs_day1 = substitute[0].subs_day1;
            this.model.subs_day2 = substitute[0].subs_day2;
        },
        holidayRules: function(holidayRules) {
            this.model.holiday_detail = cloneDeep(holidayRules);
        }
    },
    beforeRouteLeave(to, from, next) {
        this.$store.commit("humanResource/holidayRule/RESET_STATE");
        next();
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.fetchData();
        });
    }
};
</script>

<style lang="css" scoped>
#container-table {
    max-height: 67vh;
    overflow-y: scroll;
    overflow-x: hidden;
    scrollbar-width: none;
    -ms-overflow-style: none;
}
::-webkit-scrollbar {
    width: 3px; /* Remove scrollbar space */
    background: transparent; /* Optional: just make scrollbar invisible */
}
/* Optional: show position indicator in red */
::-webkit-scrollbar-thumb {
    background: rgb(194, 194, 194);
}
.table > thead > tr > th {
    position: sticky;
    position: -webkit-sticky;
    top: 0;
    z-index: 100;
}
.table > tbody > tr > td {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
    border: none !important;
}
.only-border-bottom {
    border-top: none;
    border-left: none;
    border-right: none;
}
</style>
